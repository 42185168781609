import React, { PureComponent } from 'react';
import { withCapoeiraSportState } from 'src/ver2/context';
import PersonAvatar from './avatar';
import GroupAvatar from 'src/ver2/components/group/avatar';
import SchoolAvatar from 'src/ver2/components/school/avatar';
import CordaoAvatar from 'src/ver2/components/cordao/avatar';
import PlaceRect from 'src/ver2/containers/lowlavel/placeRect';
import { ItemIconInused } from 'src/ver2/components/item/icon';

import { Link } from 'react-router-dom';

import { EditIcon, DeleteIcon } from 'src/ver2/components/ui/icons';

import { Tooltip } from 'antd';

import './littleStyle.css';
import PersonWrapper from './personWrapper';
import Loader from 'src/ver2/components/loader';
import moment from 'moment';

//getEventById

/**
 * ПОЛУЧАЕТ НА ВХОД 
 * При подключении передаются personId, height (стандарт 40) и mode (если нужен)
 
    Person
 * personsDirect - это значит передаёт самого персона в DirectPerson
 *  
 */

class LittlePerson extends PureComponent {

    // constructor(props) {
    //     super(props);
    // }

    iconComponent = ({ VSettings = '', height, scaled = 0.3 }) => {

        if (!VSettings || VSettings === '') return null;

        const VSettings_ = JSON.parse(VSettings);
        if (!VSettings_ || !VSettings_.icon) return null;

        return <ItemIconInused Name={VSettings_.icon} style={{ width: height * scaled, height: height * scaled, marginRight: height * scaled / 0.3 * 0.02, marginTop: -height * scaled / 0.3 * 0.08 }} />
    }

    render() {

        const { Person: PersonFrowWrapper, DirectPerson, height: comingHeight, Suffix, mode, noNameNoLink, onStudentChange, onCompetitorChange } = this.props;

        const height = comingHeight || 40;

        const Person = PersonFrowWrapper || DirectPerson;

        if (!Person) return <div style={{ height, minHeight: height }} className='person_little person_little_loading'>
            <Loader />
        </div>

        let iconComponent = null;

        const {
            Id, Name, Surname, Apelido, Cordao, SchoolId, GroupIamInId, Place, Gold, Silver, Bronze, SportRank, JudgeRank, VSettings, Tel
        } = Person;

        // для большой видео-плиты
        if (mode === 'videoplate') {
            return <div style={{ height, minHeight: height }} className='person_little person_little_videoplate'>
                <div className='person_little_videoplate_back'>
                    <SchoolAvatar size={height * 1.2}  {...{ schoolId: SchoolId }} />
                </div>
                <div style={{ width: height * 0.8 }}>
                    <PersonAvatar key={'perav' + Id} personId={Id} size={height * 0.8} />
                </div>

                <div className='person_little_videoplate_right'>
                    <div className='person_little_videoplate_right_name' style={{ fontSize: height * 0.15, lineHeight: height * (this.props.isMobile ? 0.015 : 0.005) }}>
                        <Link to={'/person/' + Id}>
                            {Surname} {Name}
                        </Link>
                    </div>
                    <div className='person_little_videoplate_right_stuff'>
                        <CordaoAvatar size={height * 0.4}  {...{ SchoolId, Cordao }} />
                        <GroupAvatar size={height * 0.4}  {...{ groupId: GroupIamInId }} />
                    </div>
                </div>
            </div>
        }
        if (mode === 'videoplate_left') {
            return <div style={{ height, minHeight: height }} className='person_little person_little_videoplate person_little_videoplate_left'>
                <div className='person_little_videoplate_back'>
                    <SchoolAvatar size={height * 1.2}  {...{ schoolId: SchoolId }} />
                </div>
                <div className='person_little_videoplate_right'>
                    <div className='person_little_videoplate_right_name' style={{ fontSize: height * 0.15, lineHeight: height * (this.props.isMobile ? 0.015 : 0.005) }}>
                        <Link to={'/person/' + Id}>
                            {Surname} {Name}
                        </Link>
                    </div>
                    <div className='person_little_videoplate_right_stuff'>
                        <GroupAvatar size={height * 0.4}  {...{ groupId: GroupIamInId }} />
                        <CordaoAvatar size={height * 0.4}  {...{ SchoolId, Cordao }} />
                    </div>
                </div>
                <div style={{ width: height * 0.8 }}>
                    <PersonAvatar key={'perav' + Id} personId={Id} size={height * 0.8} />
                </div>
            </div>
        }

        if (mode === 'categorys') {
            return <div style={{ height, minHeight: height }} className='person_little person_little_categorys'>
                <SchoolAvatar size={height * 0.65}  {...{ schoolId: SchoolId }} />
                <GroupAvatar size={height * 0.65}  {...{ groupId: GroupIamInId }} />
                <CordaoAvatar size={height * 0.65}  {...{ SchoolId, Cordao }} />
                <PersonAvatar key={'perav' + Id} personId={Id} size={height * 0.65} />
                <div className='person_little_category_name'>
                    <Link to={'/person/' + Id}>
                        {Surname} {Name}
                    </Link>
                </div>

            </div>
        }

        if (mode === 'bet') {
            return <div style={{ height, minHeight: height }} className='person_little person_little_categorys'>
                <SchoolAvatar size={height * 0.65}  {...{ schoolId: SchoolId }} />
                <Link to={'/person/' + Id}>
                    <PersonAvatar key={'perav' + Id} personId={Id} size={height * 0.65} />
                </Link>
                <div className='person_little_category_name person_little_category_name_bets'>
                    {Surname} {Name}
                </div>
                {
                    Suffix && <div className='person_little__suffix person_little__suffix_bets'>
                        {Suffix}
                    </div>
                }
            </div>
        }

        if (mode === 'video_trefatext') {
            return <div style={{ height, minHeight: height }} className='person_little person_little_categorys'>
                <SchoolAvatar size={height * 0.65}  {...{ schoolId: SchoolId }} />
                <GroupAvatar size={height * 0.65}  {...{ groupId: GroupIamInId }} />
                <CordaoAvatar size={height * 0.65}  {...{ SchoolId, Cordao }} />
                <PersonAvatar key={'perav' + Id} personId={Id} size={height * 0.65} />
                <div className='person_little_category_name person_little_video_trefatext'>
                    <span onClick={() => { this.props.onLinkClick && this.props.onLinkClick('/person/' + Id) }}>
                        {Surname} {Name}
                    </span>
                </div>

            </div>
        }

        if (mode === 'categorys_admin') {
            return <div style={{ height, minHeight: height }} className='person_little person_little_categorys'>
                <SchoolAvatar size={height * 0.65}  {...{ schoolId: SchoolId }} />
                <GroupAvatar size={height * 0.65}  {...{ groupId: GroupIamInId }} />
                <CordaoAvatar size={height * 0.65}  {...{ SchoolId, Cordao }} />
                <PersonAvatar key={'perav' + Id} personId={Id} size={height * 0.65} DrawRaiting={true} />
                <div className='person_little_category_name'>
                    <Link to={'/person/' + Id}>
                        {Surname} {Name}
                    </Link>
                </div>
                {onCompetitorChange ? <div onClick={() => { onCompetitorChange(Id, Surname + ' ' + Name) }} className='person_little_category_admin_move'>перенос</div> : null}

            </div>
        }

        if (mode === 'results') {
            return <div style={{ height, minHeight: height }} className='person_little person_little_results'>
                <div className='person_little_results_place'>
                    <PlaceRect height={height * (this.props.isMobile ? 0.6 : 0.7)} Place={Place} PlaceName={Place} />
                </div>
                <div className='person_little_results_name'>
                    <Link to={'/person/' + Id}>
                        {Surname} {Name}
                    </Link>
                </div>
                <PersonAvatar key={'perav' + Id} personId={Id} size={height * 0.65} />
                <SchoolAvatar size={height * 0.65}  {...{ schoolId: SchoolId }} />
                <GroupAvatar size={height * 0.65}  {...{ groupId: GroupIamInId }} />
                <CordaoAvatar size={height * 0.65}  {...{ SchoolId, Cordao }} />
            </div>
        }


        if (mode === 'students') {
            const { ServedTo, NowProPlace, NowProPlaceName, NowNotProPlace, NowNotProPlaceName, LastProPlace, LastProPlaceName, LastNotProPlace } = Person;
            const { studentsRankRace } = this.props;
            if (Person.Surname === 'Бушуева') {
                console.log('Бушуева');
            }

            let TakePro = 1;
            if (NowProPlace < 1) TakePro = 0;

            if (NowProPlace >= 1 && NowNotProPlace >= 1) {
                if ((NowNotProPlace < NowProPlace)) TakePro = 0;
            }

            let NowPlace = NowProPlace;
            let NowPlaceName = NowProPlaceName;
            let NowNotPro = 1;

            if (!TakePro) {
                NowPlace = NowNotProPlace;
                NowNotPro = 0.65;
                NowPlaceName = NowNotProPlaceName;
            }

            let RaceCount = null;
            if (studentsRankRace && NowPlace) {
                if (TakePro && LastProPlace > 0) {
                    RaceCount = LastProPlace - NowPlace;
                }
                if (!TakePro && LastNotProPlace > 0) {
                    RaceCount = LastNotProPlace - NowPlace;
                }
            }
            if (RaceCount === 0) RaceCount = null;

            if (this.props.isMobile) {
                return <div style={{ height, minHeight: height }} className='person_little'>
                    <div style={{ width: height, minWidth: height, height }} className='person_little__avatar'>
                        <PersonAvatar key={'perav' + Id} personId={Id} size={height * 0.65} />
                        <div className='person_little__cordao' >
                            <CordaoAvatar size={height * 0.5} {...{ SchoolId, Cordao }} />
                            <SchoolAvatar size={height * 0.4} style={{ marginLeft: 5 }} {...{ schoolId: SchoolId }} />
                            {SportRank ?
                                <Tooltip placement='top' title={'Спортивный разряд: ' + this.props.client.sportRanks[SportRank]}>
                                    <img style={{ width: height * 0.4, height: height * 0.4, marginLeft: 5 }} src={`/../images/ver2/cats/${SportRank}.png`} />
                                </Tooltip>
                                : null}
                            {JudgeRank ?
                                <Tooltip placement='top' title={'Судейская категория: ' + this.props.client.judgeRanks[JudgeRank]}>
                                    <img style={{ width: height * 0.4, height: height * 0.4, marginLeft: 5 }} src={`/../images/ver2/cats/${JudgeRank}.png`} />
                                </Tooltip>
                                : null}
                            {(ServedTo && moment(ServedTo, 'YYYY.MM.DDTHH:mm').isAfter(moment('02.09.2024', 'DD.MM.YYYY'))) ?
                                <Tooltip placement='top' title={'Аккредитация ФКР до: ' + moment(ServedTo, 'YYYY.MM.DDTHH:mm').format('DD.MM.YYYY')}>
                                    <img style={{ width: height * 0.4, height: height * 0.4, marginLeft: 5 }} src="/../images/ver2/fkr_logo.png" />
                                </Tooltip>
                                : null}
                        </div>
                    </div>
                    <div style={{ fontSize: Math.max(height * 0.3, 14), lineHeight: Math.max(height * 0.022, 1) }} className='person_little__fio_student'>
                        <Link to={'/person/' + Id}>
                            <this.iconComponent {...{ VSettings, height, scaled: 0.6 }} />{Surname} {Name}
                        </Link>
                    </div>
                    <div className='person_little_results_place'>
                        <PlaceRect height={height * 0.4} Place={1} PlaceName={Gold} asMedal={true} />
                        <PlaceRect height={height * 0.4} Place={2} PlaceName={Silver} asMedal={true} />
                        <PlaceRect height={height * 0.4} Place={3} PlaceName={Bronze} asMedal={true} />
                    </div>
                    <div className='person_little_results_rank_mobile'>
                        {NowPlace && <PlaceRect style={{ opacity: NowNotPro }} height={height * 0.4} Place={NowPlace} PlaceName={NowPlaceName} asMedal={false} />}
                        {RaceCount && (
                            RaceCount > 0 ?
                                <div className='person_little_results_race'>
                                    <img src="/../images/ver2/triangleup.png" />
                                    <span>
                                        {`+${RaceCount}`}
                                    </span>
                                </div>
                                :
                                <div className='person_little_results_race person_little_results_down'>
                                    <span>
                                        {RaceCount}
                                    </span>
                                    <img src="/../images/ver2/triangledown.png" />
                                </div>
                        )
                        }
                    </div>
                    {onStudentChange ? <div className='person_little_students_change'>
                        <Tooltip placement='top' title={'Изменить данные'} >
                            <span onClick={() => { onStudentChange(Id) }}>
                                <EditIcon width={height * 0.4} height={height * 0.4} />
                            </span>
                        </Tooltip>
                        <Tooltip placement='top' title={'удалить из группы'} >
                            <span onClick={() => { onStudentChange(Id, true) }}>
                                <DeleteIcon width={height * 0.4} height={height * 0.4} />
                            </span>
                        </Tooltip>
                    </div> : null}

                </div>
            }

            return <div style={{ height, minHeight: height }} className='person_little person_little_results'>
                <div className='person_little_students_rank'>
                    {SportRank ?
                        <Tooltip placement='top' title={'Спортивный разряд: ' + this.props.client.sportRanks[SportRank]}>
                            <img style={{ width: height * 0.55, height: height * 0.55 }} src={`/../images/ver2/cats/${SportRank}.png`} />
                        </Tooltip>
                        : null}
                </div>
                <div className='person_little_students_rank'>
                    {JudgeRank ?
                        <Tooltip placement='top' title={'Судейская категория: ' + this.props.client.judgeRanks[JudgeRank]}>
                            <img style={{ width: height * 0.55, height: height * 0.55 }} src={`/../images/ver2/cats/${JudgeRank}.png`} />
                        </Tooltip>
                        : null}
                </div>
                <PersonAvatar key={'perav' + Id} personId={Id} size={height * 0.65} />
                <CordaoAvatar size={height * 0.65}  {...{ SchoolId, Cordao }} />
                <div className='person_little_students_name'>
                    <Link to={'/person/' + Id}>
                        <this.iconComponent {...{ VSettings, height, scaled: 0.6 }} />{`${Surname} ${Name} ${(Apelido && Apelido !== '' && Apelido !== ' ') ? ' / ' + Apelido : ''}`}
                    </Link>
                </div>
                <div className='person_little_results_place'>
                    <PlaceRect height={height * 0.7} Place={1} PlaceName={Gold} asMedal={true} />
                    <PlaceRect height={height * 0.7} Place={2} PlaceName={Silver} asMedal={true} />
                    <PlaceRect height={height * 0.7} Place={3} PlaceName={Bronze} asMedal={true} />
                </div>
                {!studentsRankRace ?
                    (ServedTo && moment(ServedTo, 'YYYY.MM.DDTHH:mm').isAfter(moment('02.09.2024', 'DD.MM.YYYY'))) ?
                        <Tooltip placement='top' title={'Аккредитация ФКР до: ' + moment(ServedTo, 'YYYY.MM.DDTHH:mm').format('DD.MM.YYYY')}>
                            <img style={{ width: height * 0.55, height: height * 0.55, marginLeft: 15 }} src="/../images/ver2/fkr_logo.png" />
                        </Tooltip>
                        : <div style={{ width: height * 0.55, height: height * 0.55, marginLeft: 15 }} />
                    : <div style={{ width: height * 0.55, height: height * 0.55 }} />
                }
                <div className='person_little_students_rank'>
                    {NowPlace && <PlaceRect style={{ opacity: NowNotPro }} height={height * 0.7} Place={NowPlace} PlaceName={NowPlaceName} asMedal={false} />}
                    {RaceCount && (
                        RaceCount > 0 ?
                            <div className='person_little_results_race'>
                                <img src="/../images/ver2/triangleup.png" />
                                <span>
                                    {`+${RaceCount}`}
                                </span>
                            </div>
                            :
                            <div className='person_little_results_race person_little_results_down'>
                                <span>
                                    {RaceCount}
                                </span>
                                <img src="/../images/ver2/triangledown.png" />
                            </div>
                    )

                    }
                </div>
                {onStudentChange ? <div className='person_little_students_change'>
                    <Tooltip placement='top' title={'Изменить данные'} >
                        <span onClick={() => { onStudentChange(Id) }}>
                            <EditIcon width={height * 0.4} height={height * 0.4} />
                        </span>
                    </Tooltip>
                    <Tooltip placement='top' title={'удалить из группы'} >
                        <span onClick={() => { onStudentChange(Id, true) }}>
                            <DeleteIcon width={height * 0.4} height={height * 0.4} />
                        </span>
                    </Tooltip>
                </div> : null}
            </div >
        }

        if (mode === 'participators_admin') {

            if (this.props.isMobile) {
                return <div style={{ height, minHeight: height }} className='person_little'>
                    <div style={{ width: height - 5, minWidth: height, height }} className='person_little__avatar'>
                        <PersonAvatar key={'perav' + Id} personId={Id} size={height * 0.65} />
                        <div className='person_little__cordao' >
                            <CordaoAvatar size={height * 0.5} {...{ SchoolId, Cordao }} />
                            <SchoolAvatar size={height * 0.4} style={{ marginLeft: 5 }} {...{ schoolId: SchoolId }} />
                            <GroupAvatar size={height * 0.4} style={{ marginLeft: 5 }} {...{ groupId: GroupIamInId }} />
                        </div>
                    </div>
                    <div style={{ fontSize: Math.max(height * 0.3, 14), lineHeight: Math.max(height * 0.022, 1) }} className='person_little__fio'>
                        <Link to={'/person/' + Id}>
                            {Surname} {Name}
                        </Link>
                    </div>
                </div>
            }

            return <div style={{ height, minHeight: height }} className='person_little person_little_results'>
                <PersonAvatar key={'perav' + Id} personId={Id} size={height * 0.65} />
                <div className='person_little_results_name'>
                    <Link to={'/person/' + Id}>
                        {Surname} {Name}
                    </Link>
                </div>
                <SchoolAvatar size={height * 0.65}  {...{ schoolId: SchoolId }} />
                <GroupAvatar size={height * 0.65}  {...{ groupId: GroupIamInId }} />
            </div>
        }

        if (mode === 'group_instructors') {
            return <div style={{ height, minHeight: height }} className='person_little'>
                <div style={{ width: height, minWidth: height, height }} className='person_little__avatar'>
                    <PersonAvatar key={'perav' + Id} personId={Id} size={height * 0.65} />
                    <div className='person_little__cordao' >
                        <CordaoAvatar size={height * 0.5} {...{ SchoolId, Cordao }} />
                        <SchoolAvatar size={height * 0.4} style={{ marginLeft: 5 }} {...{ schoolId: SchoolId }} />
                        <GroupAvatar size={height * 0.4} style={{ marginLeft: 5 }} {...{ groupId: GroupIamInId }} />
                    </div>
                </div>
                <div style={{ fontSize: Math.max(height * 0.3, 14), lineHeight: Math.max(height * 0.022, 1) }} className='person_little__fio'>
                    <Link to={'/person/' + Id}>
                        <this.iconComponent {...{ VSettings, height, scaled: 0.3 }} /> {Surname} {Name}
                    </Link>
                </div>
                {
                    Suffix && <div className='person_little__suffix person_little__suffix_gi'>
                        {Suffix}
                    </div>
                }
            </div>
        }


        if (mode === 'contact' || mode === 'contact_noclick') {
            const { onPersonClick } = this.props;
            return <div style={{ height, minHeight: height }} className={onPersonClick ? 'person_little person_little_clickable' : 'person_little'} onClick={onPersonClick ? () => onPersonClick(Id) : () => { }}>
                <div style={{ width: height, minWidth: height, height }} className='person_little__avatar'>
                    <PersonAvatar key={'perav' + Id} personId={Id} size={height * 0.65} />
                    <div className='person_little__cordao' >
                        <CordaoAvatar size={height * 0.5} {...{ SchoolId, Cordao }} />
                        <SchoolAvatar size={height * 0.4} style={{ marginLeft: 5 }} {...{ schoolId: SchoolId }} />
                        <GroupAvatar size={height * 0.4} style={{ marginLeft: 5 }} {...{ groupId: GroupIamInId }} />
                        <div className='person_little__contact'>
                            {Tel}
                        </div>
                    </div>
                </div>
                <div style={{ fontSize: Math.max(height * 0.3, 14), lineHeight: Math.max(height * 0.022, 1) }} className='person_little__fio'>
                    {mode === 'contact_noclick' ?
                        <>
                            <this.iconComponent {...{ VSettings, height, scaled: 0.3 }} /> {Surname} {Name}
                        </>
                        :
                        <Link to={'/person/' + Id}>
                            <this.iconComponent {...{ VSettings, height, scaled: 0.3 }} /> {Surname} {Name}
                        </Link>
                    }
                </div>
                {
                    Suffix && <div className='person_little__suffix'>
                        {Suffix}
                    </div>
                }
            </div>
        }

        if (mode === 'fio') {
            return <div className='person_little__mode_fio'>
                <this.iconComponent {...{ VSettings, height, scaled: 1 }} /> {Surname} {Name} {(Apelido && Apelido !== ' ' && Apelido !== '') ? ('/ ' + Apelido) : ''}
            </div>
        }

        if (mode === 'no_name') {
            return <div style={{ height, minHeight: height }} className='person_little'>
                <div style={{ width: height, minWidth: height, height }} className='person_little__avatar'>
                    <PersonAvatar key={'perav' + Id} personId={Id} size={height * 0.65} />
                    <div className='person_little__cordao' >
                        <CordaoAvatar size={height * 0.5} {...{ SchoolId, Cordao }} />
                    </div>
                </div>
                <div style={{ fontSize: Math.max(height * 0.3, 14), lineHeight: Math.max(height * 0.022, 1) }} className='person_little__fio'>
                    {
                        noNameNoLink ?
                            <this.iconComponent {...{ VSettings, height, scaled: 0.5 }} />
                            :
                            <Link to={'/person/' + Id}>
                                <this.iconComponent {...{ VSettings, height, scaled: 0.5 }} />
                            </Link>
                    }
                </div>
            </div>
        }

        if (mode === 'suffix_down') {
            return <div style={{ height, minHeight: height }} className='person_little'>
                <div style={{ width: height, minWidth: height, height }} className='person_little__avatar'>
                    <PersonAvatar key={'perav' + Id} personId={Id} size={height * 0.65} />
                    <div className='person_little__cordao' >
                        <CordaoAvatar size={height * 0.5} {...{ SchoolId, Cordao }} />
                        <SchoolAvatar size={height * 0.4} style={{ marginLeft: 5 }} {...{ schoolId: SchoolId }} />
                        <GroupAvatar size={height * 0.4} style={{ marginLeft: 5 }} {...{ groupId: GroupIamInId }} />
                        {Suffix && <div className='person_little__suffix'>
                            {Suffix}
                        </div>}
                    </div>
                </div>
                <div style={{ fontSize: Math.max(height * 0.3, 14), lineHeight: Math.max(height * 0.022, 1) }} className='person_little__fio'>
                    <Link to={'/person/' + Id}>
                        <this.iconComponent {...{ VSettings, height, scaled: 0.3 }} /> {Surname} {Name}
                    </Link>
                </div>
            </div>
        }

        return <div style={{ height, minHeight: height }} className='person_little'>
            <div style={{ width: height, minWidth: height, height }} className='person_little__avatar'>
                <PersonAvatar key={'perav' + Id} personId={Id} size={height * 0.65} />
                <div className='person_little__cordao' >
                    <CordaoAvatar size={height * 0.5} {...{ SchoolId, Cordao }} />
                    <SchoolAvatar size={height * 0.4} style={{ marginLeft: 5 }} {...{ schoolId: SchoolId }} />
                    <GroupAvatar size={height * 0.4} style={{ marginLeft: 5 }} {...{ groupId: GroupIamInId }} />
                </div>
            </div>
            <div style={{ fontSize: Math.max(height * 0.3, 14), lineHeight: Math.max(height * 0.022, 1) }} className='person_little__fio'>
                <Link to={'/person/' + Id}>
                    <this.iconComponent {...{ VSettings, height, scaled: 0.3 }} /> {Surname} {Name}
                </Link>
            </div>
            {
                Suffix && <div className='person_little__suffix'>
                    {Suffix}
                </div>
            }
        </div>
    }

}

const componentWrapped = (props) => <PersonWrapper Wrapped={LittlePerson} WithTel={props.mode === 'contact' || props.mode === 'contact_noclick'} personId={props.personsDirect ? null : props.personId} componentProps={{ ...props }} />
export default withCapoeiraSportState('iamuser, client, isMobile')(componentWrapped);
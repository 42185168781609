// это у нас основной файл роутинга
import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';
import Loader from 'src/ver2/components/loader';

// const MainPage = React.lazy(() => import('./main'));
// const PersonPage = React.lazy(() => import('./person'));
// const SchoolPage = React.lazy(() => import('./school'));
// const AdminPage = React.lazy(() => import('./admin'));
// const RaitingPage = React.lazy(() => import('./raiting'));
// const EventPage = React.lazy(() => import('./event'));
// const JudgePage = React.lazy(() => import('./judge'));
// const CalendarPage = React.lazy(() => import('./calendar'));
// const GroupPage = React.lazy(() => import('./group'));

import MainPage from './main';
import CalendarPage from './calendar';
import SchoolPage from './school';
import PersonPage from './person';
import RaitingPage from './raiting';
import GroupPage from './group';

// import JudgePage from './judge';
// import AdminPage from './admin';
// import EventPage from './event';
// import TechincPage from './technic';
// import ShopPage from './shop';
// import SequencePage from './sequence';
// import RegionsPage from './regions';
// import RegionPage from './region';
// import RoundPage from './round';
// import StatisticsPage from './statistics';
// import FkrAccredPage from './fkraccred';
// import CRMPage from './crm';
// import ExercisePage from './exercise';
// import ComboPage from './combo';
// import SubgroupPage from './subgroup';

const VideoPage = React.lazy(() => import('./video'));
const VideosPage = React.lazy(() => import('./videos'));
const TablePage = React.lazy(() => import('./table'));
const SeqGamePage = React.lazy(() => import('./seqgame'));
const ResultPage = React.lazy(() => import('./result'));
const SchoolsPage = React.lazy(() => import('./schools'));
const MedalsPage = React.lazy(() => import('./medals'));
const StatsPage = React.lazy(() => import('./stats'));
const SeasonResults = React.lazy(() => import('./seasonresults'));
const StatCrm = React.lazy(() => import('./statcrm'));
const SeqStat = React.lazy(() => import('./seqstat'));
const SportLand = React.lazy(() => import('./sportland'));
const CapoeiraShow = React.lazy(() => import('./capoeirashow'));
const IslConstructor = React.lazy(() => import('./isl_constructor'));
const CmbConstructor = React.lazy(() => import('./cmb_constructor'));
const MilagrePage = React.lazy(() => import('./milagre'));
const SoForcaPage = React.lazy(() => import('./soforca'));
const TransferPage = React.lazy(() => import('./transfer'));
const MusicPage = React.lazy(() => import('./music'));
const AchivmentsPage = React.lazy(() => import('./achivments'));
const VkVideoPage = React.lazy(() => import('./vkvideo'));
const SeqPage = React.lazy(() => import('./seq_page'));
const ArticlesPage = React.lazy(() => import('./article'));

// перекинул в сентября 2024
const IslandPage = React.lazy(() => import('./island'));

const JudgePage = React.lazy(() => import('./judge'));
const AdminPage = React.lazy(() => import('./admin'));
const EventPage = React.lazy(() => import('./event'));
const TechincPage = React.lazy(() => import('./technic'));
const ShopPage = React.lazy(() => import('./shop'));
const SequencePage = React.lazy(() => import('./sequence'));
const RegionsPage = React.lazy(() => import('./regions'));
const RegionPage = React.lazy(() => import('./region'));
const RoundPage = React.lazy(() => import('./round'));
const StatisticsPage = React.lazy(() => import('./statistics'));
const FkrAccredPage = React.lazy(() => import('./fkraccred'));
const CRMPage = React.lazy(() => import('./crm'));
const ExercisePage = React.lazy(() => import('./exercise'));
const ComboPage = React.lazy(() => import('./combo'));
const SubgroupPage = React.lazy(() => import('./subgroup'));


export default class CapoeiraRoute extends Component {

    /*
    constructor (props) {
        super(props);
    }
    */

    render() {
        return <div className='myclassname'>
            <Switch>
                <Route exact path='/' component={MainPage} />
                <Route path='/calendar/' component={CalendarPage} />
                <Route path='/person/:personId' component={PersonPage} />
                <Route path='/school/:schoolId' component={SchoolPage} />
                <Route path='/group/:groupId' component={GroupPage} />
                <Route path='/raiting/' component={RaitingPage} />


                {/* <Route path='/admin/' component={AdminPage} />
                <Route path='/event/:eventId' component={EventPage} />
                <Route path='/shop/:shopId' component={ShopPage} />
                <Route path='/judge/' component={JudgePage} />
                <Route path='/technic/:technicname' component={TechincPage} />
                <Route path='/sequence/:code' component={SequencePage} />
                <Route path='/regions' component={RegionsPage} />
                <Route path='/region/:region' component={RegionPage} />
                <Route path='/round/:roundId' component={RoundPage} />
                <Route path='/statistics' component={StatisticsPage} />
                <Route path='/fkraccred' component={FkrAccredPage} />
                <Route path='/crm/:personId' component={CRMPage} />
                <Route path='/crm' component={CRMPage} />
                <Route path='/exercise/:exerciseId' component={ExercisePage} />
                <Route path='/combo/:comboId' component={ComboPage} /> */}

                <React.Suspense fallback={<p style={{ width: '100%', height: '300px' }}> <Loader /> </p>}>
                    <Route path='/table/' component={TablePage} />
                    <Route path='/medals/:eventId' component={MedalsPage} />
                    <Route path='/seqgame/' component={SeqGamePage} />
                    <Route path='/video/' component={VideoPage} />
                    <Route path='/vkvideo/:vk_key' component={VkVideoPage} />
                    <Route path='/videos/' component={VideosPage} />
                    <Route path='/result/' component={ResultPage} />
                    <Route path='/schools/' component={SchoolsPage} />
                    <Route path='/stats/' component={StatsPage} />
                    <Route path='/seasonresult' component={SeasonResults} />
                    <Route path='/seasonresult/:personId' component={SeasonResults} />
                    <Route path='/statcrm/' component={StatCrm} />
                    <Route path='/seqstat/' component={SeqStat} />
                    <Route path='/sportland/' component={SportLand} />
                    <Route path='/capoeirashow/' component={CapoeiraShow} />
                    <Route path='/islconstructor/' component={IslConstructor} />
                    <Route path='/cmbconstructor/' component={CmbConstructor} />
                    <Route path='/milagre/' component={MilagrePage} />
                    <Route path='/soforca/' component={SoForcaPage} />
                    <Route path='/subgroup/:subgroupId' component={SubgroupPage} />
                    <Route path='/transfer/' component={TransferPage} />
                    <Route path='/music/' component={MusicPage} />
                    <Route path='/achivments/' component={AchivmentsPage} />
                    <Route path='/island/:islandId' component={IslandPage} />
                    <Route path='/island' component={IslandPage} />
                    <Route path='/admin/' component={AdminPage} />
                    <Route path='/event/:eventId' component={EventPage} />
                    <Route path='/shop/:shopId' component={ShopPage} />
                    <Route path='/judge/' component={JudgePage} />
                    <Route path='/technic/:technicname' component={TechincPage} />
                    <Route path='/sequence/:code' component={SequencePage} />
                    <Route path='/regions' component={RegionsPage} />
                    <Route path='/region/:region' component={RegionPage} />
                    <Route path='/round/:roundId' component={RoundPage} />
                    <Route path='/statistics' component={StatisticsPage} />
                    <Route path='/fkraccred' component={FkrAccredPage} />
                    <Route path='/crmperson/:personId' component={CRMPage} />
                    <Route path='/crm' component={CRMPage} />
                    <Route path='/exercise/:exerciseId' component={ExercisePage} />
                    <Route path='/combo/:comboId' component={ComboPage} />
                    <Route path='/seqpage/' component={SeqPage} />
                    <Route path='/article/:articleId' component={ArticlesPage} />
                </React.Suspense>

                {/* <React.Suspense fallback={<p style = {{width: '100%', height: '300px'}}> <Loader/> </p>}>
                    <Route exact path='/' component={MainPage} />
                    <Route path='/person/:personId' component={PersonPage} />  
                    <Route path='/school/:schoolId' component={SchoolPage} />   
                    <Route path='/group/:groupId' component={GroupPage} /> 
                    <Route path='/raiting/' component={RaitingPage} />             
                    <Route path='/admin/' component={AdminPage} /> 
                    <Route path='/event/:eventId' component={EventPage} /> 
                    <Route path='/judge/' component={JudgePage} /> 
                    <Route path='/calendar/' component={CalendarPage} /> 
                    <Route path='/table/' component={TablePage} />
                    <Route path='/result/' component={ResultPage} />
                </React.Suspense> */}

                {/* <Route exact path='/events/:eventId' component={this.eventRuote}/> 
                <Route path = '/news/:LookedNewsId' component = {this.onenewsRoute} />  
                <Route path = '/article/:lookedArticleId' component = {this.onearticleRoute} />
                <Route path = '/person/:personId' component = {this.personPageRoute} />   
                <Route path = '/zapiski' component = {this.zapiskiPageRoute} />  
                <Route path = '/onlinetraining/:lookedId' component = {this.oneonlineTrainingRoute} />
                <Route path = '/pedestal2019' component = {this.pedestalPageRoute} />
                <Route path = '/dndTry' component = {this.dndTryPageRoute} />
                <Route path = '/solo' component = {this.soloPageRoute} />
                <Route path = '/groupmanager' component = {this.groupmanagerPageRoute} /> */}

            </Switch>
        </div>
    }
}

import React, { Component } from 'react';
import { Modal, Badge } from 'antd';
import LoginBar from './components/loginbar';
import { Notification } from './components/notification';
import PersonAvatar from 'src/ver2/components/person/avatar';
import PersonRegistration from 'src/ver2/containers/personRegistration';
import SocialIcon from 'src/ver2/components/ui/social/index.jsx'

//import { withCapoeiraSportState } from '../../../../context';
import { withCapoeiraSportState } from 'src/ver2/context';
import { Link } from 'react-router-dom';
import ModalNotification from './components/notificationModal';
import './style.css';


class Login extends Component {


    constructor(props) {
        super(props);

        this.state = {
            loginValue: '',
            passwordValue: '',
            modalLoginVisible: false,
            showMiniMenu: false,
            modalRegistrationVisible: false,
            modalNotificationVisible: false
        };
    }

    clearLogPass = () => this.setState({ loginValue: '', passwordValue: '', modalLoginVisible: false })
    loginChange = (e) => this.setState({ loginValue: e.target.value })
    passwordChange = (e) => this.setState({ passwordValue: e.target.value })
    showMiniMenuChange = () => this.setState({ showMiniMenu: !this.state.showMiniMenu })
    showMiniMenuOut = () => this.setState({ showMiniMenu: false })

    clearModalNotification = () => this.setState({ modalNotificationVisible: false })
    showModalNotification = () => this.setState({ modalNotificationVisible: true })

    // нажали на Войти
    clickOnEnter = () => { this.props.login(this.state.loginValue, this.state.passwordValue) }
    clickOnForget = () => {

        Modal.warning({
            title: 'Восстановление пароля',
            content: <div className='forget_login_container'>
                <span>Для восстановления пароля обратитесь в техническую поддержку через Telegram или WhatsUp</span>
                <span>Сообщите Имя Фамилию спортсмена и дополнительные данные при необходимости</span>
                <span>+7 926 014 72 85<br/>Анастасия</span>
                <span>Для связи нажмите одну из кнопок ниже</span>
                <div className='forget_login_container_social'>
                    <SocialIcon size={70} type='telegram' src='timida_sf' />
                    <SocialIcon size={70} type='wu' src='+79260147285' />
                </div>
            </div>,
            onOk: () => { },
            onCancel: () => { },
            okText: 'Ок',
            closable: true,
            centered: true
        })

    }

    clickOnRegistration = () => {
        this.setState({
            modalRegistrationVisible: true,
            modalLoginVisible: false
        })
    }

    componentDidUpdate({ iamuser: prev_iamuser, actualNotifications: prev_actualNotifications }) {
        const { iamuser, actualNotifications } = this.props;
        // прошел логин, убираем модал и стираем значения лог пасса
        if (!prev_iamuser && iamuser) { this.clearLogPass() }

        if ((!prev_actualNotifications || !prev_actualNotifications.length) && (actualNotifications && actualNotifications.length)) {
            const actual = actualNotifications.filter(x => x.OperationCode);
            if (actual && actual.length) {
                this.showModalNotification();
            }
        }

    }

    render() {

        const { iamuser } = this.props;
        if (!iamuser) { return <this.InvateToLogin /> }

        if (iamuser) { return <this.ShowPerson /> }

        return null;
    }

    ShowPerson = () => {
        const { iamuser, actualNotifications } = this.props;
        const { modalNotificationVisible } = this.state;
        const { Name, Surname, Id } = iamuser;

        return <div className="loginShowPerson">
            <div className="loginShowPerson_personFIO" onClick={this.showMiniMenuChange}>
                {`${Surname} ${Name}`}
            </div>
            <Badge onClick={this.showMiniMenuChange} count={actualNotifications.length}>
                <div onClick={this.showMiniMenuChange} className='loginShowPerson_span'>
                    <PersonAvatar key={'perav' + Id} personId={Id} size={40} />
                </div>
            </Badge>
            <this.MiniMenu />
            {modalNotificationVisible && <Modal
                className='resourses-modal'
                visible={modalNotificationVisible}
                title='ВАМ ВЫДАНО'
                onCancel={this.clearModalNotification}
                footer={null}
            >
                <ModalNotification closeModal={this.clearModalNotification} />
            </Modal>}

        </div>
    }

    MiniMenu = () => {

        if (!this.state.showMiniMenu) return null;
        const { actualNotifications, iamuser } = this.props;
        const { Id, SchoolId, GroupId, GroupIamInId, myGroupRole, myCRMgoes, myPortalRoles = {} } = iamuser;

        const showCrm = ((SchoolId === 1) && ((myGroupRole && myGroupRole.length && myGroupRole.find(x => x.Role === "instructor")) || GroupId))

        return <>
            <div onClick={this.showMiniMenuChange} className='loginShowPerson_MiniMenu_back'>

            </div>
            <div className='loginShowPerson_MiniMenu'>

                {actualNotifications && actualNotifications.length > 0
                    ?
                    actualNotifications.map(({ Id, Body }) => {
                        return <div key={'not_k_' + Id} className='loginShowPerson_MiniMenu_item'>
                            <Notification Body={Body} onClick={() => { this.props.iSawNotification(Id) }} />
                        </div>
                    })
                    : null}

                <div onClick={this.showMiniMenuOut} className='loginShowPerson_MiniMenu_item'>
                    <Link to={'/person/' + Id}>
                        <span>Мой профиль</span>
                    </Link>
                </div>
                {SchoolId ? <div onClick={this.showMiniMenuOut} className='loginShowPerson_MiniMenu_item'>
                    <Link to={'/school/' + SchoolId + '/info'}>
                        <span>Моя школа</span>
                    </Link>
                </div> : null}
                {showCrm ? <div onClick={this.showMiniMenuOut} className='loginShowPerson_MiniMenu_item'>
                    <Link to={'/crm'}>
                        <span>CRM</span>
                    </Link>
                </div> : null}
                {(Id === 19) ? <div onClick={this.showMiniMenuOut} className='loginShowPerson_MiniMenu_item'>
                    <Link to={'/statcrm'}>
                        <span>CRM СТАТИСТИКА</span>
                    </Link>
                </div> : null}
                {GroupIamInId ? <div onClick={this.showMiniMenuOut} className='loginShowPerson_MiniMenu_item'>
                    <Link to={'/group/' + GroupIamInId}>
                        <span>Моя группа</span>
                    </Link>
                </div> : null}
                {myCRMgoes ? <div onClick={this.showMiniMenuOut} className='loginShowPerson_MiniMenu_item'>
                    <Link to={'/crmperson/' + Id}>
                        <span>Мои посещения</span>
                    </Link>
                </div> : null}
                {(myPortalRoles.creator || myPortalRoles.admin) ? <div onClick={this.showMiniMenuOut} className='loginShowPerson_MiniMenu_item'>
                    <Link to={'/admin'}>
                        <span>Админка</span>
                    </Link>
                </div> : null}
                <div onClick={this.showMiniMenuOut} className='loginShowPerson_MiniMenu_item'>
                    <span onClick={this.props.logout}>Выход</span>
                </div>


            </div>
        </>
    }

    register = ({ privateData, contactsData, schoolData, groupData }) => {
        this.setState({ modalRegistrationVisible: false });
        this.props.register({ privateData, contactsData, schoolData, groupData });
    }

    InvateToLogin = () => {
        const { getText, isMobile } = this.props;
        const { modalLoginVisible, loginValue, passwordValue, modalRegistrationVisible } = this.state;

        const { loginChange, passwordChange, clickOnEnter, clickOnForget, clickOnRegistration } = this;
        return <div className="loginNoUser">

            <Modal
                className='login-modal'
                visible={modalLoginVisible}
                title='Вход'
                onCancel={() => this.setState({ modalLoginVisible: false })}
                footer={null}
            >
                <LoginBar
                    {...{
                        loginValue, passwordValue,
                        loginChange, passwordChange,
                        clickOnEnter, clickOnForget, clickOnRegistration,
                        getText
                    }}
                />
            </Modal>

            <Modal
                className={isMobile ? 'registration-modal-mobile' : 'registration-modal'}
                visible={modalRegistrationVisible}
                title='Регистрация'
                onCancel={() => this.setState({ modalRegistrationVisible: false })}
                width={isMobile ? '90%' : '1200px'}
                footer={null}
            >
                {modalRegistrationVisible ? <PersonRegistration register={this.register} /> : null}
            </Modal>

            <div className="loginEnter" onClick={() => this.setState({ modalLoginVisible: true })}>
                {getText('registrationEnter')}
            </div>
        </div>
    }

}

export default withCapoeiraSportState('iamuser, getText, login, logout, actualNotifications, iSawNotification, register, isMobile')(Login);